import React from 'react'
import InfoDialog from './InfoDialog'
import { ErrorLogDto } from '../models';
import { format } from 'date-fns';
interface Props{
    log: ErrorLogDto
    showDialog: boolean;
    onClose: ()=> void;
}
const ErrorViewDialog = ({log,showDialog, onClose}:Props) => {
  return (
    <InfoDialog title='Error Log Details' showDialog={showDialog} onClose={onClose}>
        <div className='form-row'>
            <label className='form-label'>Date</label>
            <input className='form-input-field' disabled value={format(new Date(log.logDate),'dd/MM/yyyy HH:mm:ss')} />
        </div>
        <div className='form-row'>
            <label className='form-label'>Customer</label>
            <input className='form-input-field' disabled value={log.customerId} />
        </div>
        <div className='form-row'>
            <label className='form-label'>Function</label>
            <input className='form-input-field' disabled value={log.functionName} />
        </div>
        <div className='form-row'>
            <label className='form-label'>Error</label>
            <textarea className='form-textarea-field' disabled value={log.error} />
        </div>
    </InfoDialog>
  )
}

export default ErrorViewDialog