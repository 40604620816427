import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ApiResponseStatus, ApiResponseT, ErrorLogDto } from './models';
import { addMonths, format } from 'date-fns';
import { GoLog } from "react-icons/go";
import {FiSearch} from 'react-icons/fi';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from './components/DatePickerCustomInput';
import './App.css'
import ErrorGrid from './components/ErrorGrid';
import LoadingComponent from './components/LoadingComponent';
axios.defaults.baseURL = process.env.REACT_APP_APP_URL;
const API_KEY = "f425819f-a3ec-4f8e-8e66-1f175e63f12a";

function App() {
    const [errorLogs, setErrorLogs] = useState<ErrorLogDto[]>([]);
    const [appList, setAppList] = useState<string[]>([]);
    const [appName,setAppName] = useState("ALL");
    const [fromDate, setFromDate] = useState(addMonths(new Date(),-1));
    const [toDate, setToDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
      loadAppList();
    },[])
    const loadAppList = async () => {
      setLoading(true);
      const response = await axios.get<ApiResponseT<string[]>>(`/errorlog/apps/${API_KEY}`);
      if(response.data)
      {
        const {status,result} = response.data;
        if(status === ApiResponseStatus.responseOK)
          setAppList(result);
        else
          setAppList([]);
      }
      else
        setAppList([]);
      setLoading(false);
    }
    const getLogs = async () => {
      setLoading(true);
      const response = await axios.get<ApiResponseT<ErrorLogDto[]>>(`/errorlog/${API_KEY}?AppName=${appName}&FromDate=${format(new Date(fromDate),"yyyy/MM/dd")}&ToDate=${format(new Date(toDate),"yyyy/MM/dd")}`);
      if(response.data)
      {
        const {status,result} = response.data;
        if(status === ApiResponseStatus.responseOK)
          setErrorLogs(result);
        else
          setErrorLogs([]);
      }
      else
        setErrorLogs([]);
      setLoading(false);
    }
    const handleChangeFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setFromDate(new Date(date));
        }
    }
    const handleChangeToDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setToDate(new Date(date));
        }
    }
  
  return (
    <>
        <div className='header'>
          <div className='navbar-brand'>
              <GoLog className='navbar-logo' />
              <h1 className="logo-title">Axcess Error Log Viewer</h1>
          </div>
        </div>
        <div className='container'>
        <div className='panel'>
            <div className='search-box'>
                <select className='custom-dropdown' value={appName} onChange={(e)=>setAppName(e.target.value)}>
                  {appList.map(app => <option key={app} value={app}>{app}</option>)}
                </select>
                <DatePicker 
                    name="forDate"
                    dateFormat="dd/MM/yyyy"
                    selected={fromDate}
                    onChange={handleChangeFromDate}
                    showMonthDropdown
                    showYearDropdown 
                    dropdownMode="select"
                    todayButton="Today"
                    customInput={React.createElement(DatePickerCustomInput)}
                    />
                <DatePicker 
                    name="forDate"
                    dateFormat="dd/MM/yyyy"
                    selected={toDate}
                    onChange={handleChangeToDate}
                    showMonthDropdown
                    showYearDropdown 
                    dropdownMode="select"
                    todayButton="Today"
                    customInput={React.createElement(DatePickerCustomInput)}
                    />

              <button className='btn btn-with-icon btn-default' onClick={()=>getLogs()}><FiSearch /> Search</button>
            </div>
        </div>
        {loading && <LoadingComponent message='Loading... please wait!' />}
        {!loading && <ErrorGrid logs={errorLogs} />}
        </div>
        <div className='footer'>
        <div className='footer-container'>
          <span>{`Copyright ${format(new Date(),"yyyy")}, Axcess IT`}</span>
        </div>
      </div>
    </>
  )
}

export default App;
