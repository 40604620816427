import React, { useEffect, useState } from 'react'
import { ErrorLogDto } from '../models'
import {BsSortUpAlt,BsSortDown} from 'react-icons/bs'
import { LuClipboardCopy } from "react-icons/lu";
import { GoCodeReview } from "react-icons/go";
import { format } from 'date-fns';
import ErrorViewDialog from './ErrorViewDialog';
interface Props{
    logs: ErrorLogDto[];

}
const ErrorGrid = ({logs}:Props) => {
    const [sortedLogs,setSortedLogs] = useState<ErrorLogDto[]>(logs);
    const [sortColumn, setSortColumn] = useState(0);
    const [sortMode, setSortMode] = useState(true);
    const [showLog, setShowLog] = useState(false);
    const [currentLog, setCurrentLog] = useState<ErrorLogDto|undefined>();

    useEffect(()=>{
        console.log(sortedLogs);
    },[sortedLogs])

    const handleCopyToClipboard = (error: string) => {
        navigator.clipboard.writeText(error);
        alert("Error code copied to clipboard!");
    }
    
    const handleShowDetails = (error: ErrorLogDto) => {
        setCurrentLog(error);
        setShowLog(true);
    }
    
    const handleHideDetails = () => {
        setCurrentLog(undefined);
        setShowLog(false);
    }

    const performSorting = (column: number) => {
        setSortedLogs([]);
        let mode = sortMode;
        if(sortColumn === column){
            setSortMode(prev => !prev);
            mode = !mode;
        }
        else
            setSortColumn(column);
        switch(column){
            case 0:
                // By Date
                if(mode)
                    setSortedLogs([...logs.sort((a,b) => a.logDate && a.logDate > b.logDate ? 1 : -1)])
                else
                    setSortedLogs([...logs.sort((a,b) => a.logDate && a.logDate > b.logDate ? -1 : 1)])
                break;
            case 1:
                // By Customer
                if(mode)
                    setSortedLogs([...logs.sort((a,b) => a.customerId && a.customerId > b.customerId ? 1 : -1)])
                else
                    setSortedLogs([...logs.sort((a,b) => a.customerId && a.customerId > b.customerId ? -1 : 1)])
                break;
            case 2:
                // By Function
                if(mode)
                    setSortedLogs([...logs.sort((a,b) => a.functionName && a.functionName > b.functionName ? 1 : -1)])
                else
                    setSortedLogs([...logs.sort((a,b) => a.functionName && a.functionName > b.functionName ? -1 : 1)])
                break;
            case 3:
                // By Error
                if(mode)
                    setSortedLogs([...logs.sort((a,b) => a.error && a.error > b.error ? 1 : -1)])
                else
                    setSortedLogs([...logs.sort((a,b) => a.error && a.error > b.error ? -1 : 1)])
                break;
        }
    }
  return (
    <>
        <div className='data-grid'>
            <div className='data-grid-row heading-row'>
                <div onClick={() => performSorting(0)} className='data-grid-col'><span>Date</span>{sortColumn === 0 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => performSorting(1)} className='data-grid-col'><span>Customer</span>{sortColumn === 1 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => performSorting(2)} className='data-grid-col'><span>Function</span>{sortColumn === 2 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div onClick={() => performSorting(3)} className='data-grid-col'><span>Error</span>{sortColumn === 3 && (sortMode ? <BsSortUpAlt /> : <BsSortDown />)}</div>
                <div className='data-grid-col'>Copy</div>
                <div className='data-grid-col'>View</div>
            </div>
                {sortedLogs.length === 0 ? (
                    <div className="data-grid-full-row">No records</div>
                ) : (
                    sortedLogs.map((slog) => (
                        <div key={slog.id} className='data-grid-row'>
                            <div className='data-grid-col'>{format(new Date(slog.logDate),"dd/MM/yyyy HH:mm:ss")}</div>
                            <div className='data-grid-col'>{slog.customerId.substring(0,10)}</div>
                            <div className='data-grid-col'>{slog.functionName.substring(0,40)}</div>
                            <div className='data-grid-col'>{slog.error.substring(0,75)}</div>
                            <div className='data-grid-col'><LuClipboardCopy onClick={() => handleCopyToClipboard(slog.error)} title='Copy to Clipboard' /></div>
                            <div className='data-grid-col'><GoCodeReview onClick={()=>handleShowDetails(slog)} title='View Details' /></div>
                        </div>
                    ))
                )}
        </div>
        {showLog && currentLog && <ErrorViewDialog log={currentLog} onClose={handleHideDetails} showDialog={showLog} />}
    </>

  )
}

export default ErrorGrid